module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://conciliacionlaboral.com.mx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Conciliación Laboral Abogados","short_name":"CL Abogados","start_url":"/","background_color":"#ffffff","theme_color":"#045483","display":"standalone","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"83b1c454bfcbc90be2175fb2f5bdb6aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"53560387","trackHash":true,"clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"webvisor":true,"version":2},
    }]
